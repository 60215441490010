import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";
import {
  Check_Sub_Domain,
  updateDomainName,
} from "../../api/QR List All Api/QrListAllApi";
import { Zoom, toast } from "react-toastify";
import {
  getDomainWithoutSubdomain,
  isBusinessDomainFunc,
  isValidDomain,
  maxLengthCheck,
} from "../../utils/helper";
import LoaderContext from "../../context/LoaderContext/LoaderContext";

const ChangeDomainModal = (props) => {
  const {
    domain,
    setDomain,
    changeDomainModal,
    setChangeDomainModal,
    domainDetails,
  } = useContext(QrListAllContext);
  const [isBusinessDomain, setIsBusinessDomain] = useState(false);
  const [whichPage, setWhichPage] = useState("singlePage");
  // let isAvailable = false;
  const [isAvailableDomain, setIsAvailableDomain] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const { getData } = props;
  const { setLoaderShow } = useContext(LoaderContext);
  const [maxlength, setMaxlength] = useState(25);

  const handleEmpty = () => {
    setDomain("");
    setChangeDomainModal(false);
  };

  const handleCheckSubDomain = async () => {
    let trimString = () => {
      return domain.trim();
    };
    if (trimString().length) {
      try {
        let paramObj = {};
        if (isBusinessDomain) {
          if (isBusinessDomainFunc(trimString())) {
            paramObj["domain"] = trimString();
          } else {
            setIsAvailableDomain(false);
            throw Error("Please add valid domain.");
          }
        } else {
          paramObj["domain"] =
            trimString() +
            `.${
              domainDetails?.mainDomain
                ? domainDetails?.mainDomain
                : getDomainWithoutSubdomain(domainDetails.domain)
            }`;
        }
        if (isBusinessDomain) {
          paramObj["isBusinessDomain"] = isBusinessDomain;
        }
        const { data } = await Check_Sub_Domain(paramObj);
        if (data.status === 200) {
          setIsAvailable(true);
          // isAvailable = true;
          setIsAvailableDomain(true);
          return true;
        } else {
          setIsAvailable(false);
          // isAvailable = false;
          setIsAvailableDomain(false);
          return false;
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || error.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        // isAvailable = false;
        setIsAvailable(false);
        setIsAvailableDomain(false);
        return false;
      }
    }
  };

  const handleSubmit = async (e) => {
    // debugger;
    e.preventDefault();
    if (isAvailable) {
      try {
        setLoaderShow(true);
        let webType = "";
        if (whichPage === "singlePage") {
          webType = "mini";
        } else if (whichPage === "multiPage") {
          webType = "multi";
        }
        let paramObj = {
          domain: `${
            domainDetails?.mainDomain
              ? domainDetails?.mainDomain
              : getDomainWithoutSubdomain(domainDetails.domain)
          }`,
          subDomain: domain,
          webType: webType,
          webId: domainDetails.webId,
          option: "create",
        };
        console.log("paramObj >> ", paramObj);
        if (isBusinessDomain) {
          paramObj["isBusinessDomain"] = true;
          paramObj["domain"] = domain;
          delete paramObj.subDomain;
        } else {
          paramObj["subDomain"] = domain;
        }
        if (!isValidDomain(domain) && isBusinessDomain) {
          toast.error("Please enter your perfect domain.", {
            autoClose: 1000,
            transition: Zoom,
          });
          throw new Error("Please enter your perfect domain.");
        } else if (domain.split(".")[0] === "www") {
          toast.error("Please don't add www in subdomain", {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
          return;
        }
        let ApiUrl;
        if (whichPage === "singlePage") {
          ApiUrl = updateDomainName;
        } else if (whichPage === "multiPage") {
          ApiUrl = updateDomainName;
        }
        const { data } = await ApiUrl(paramObj);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          getData();
          handleEmpty();
          setLoaderShow(false);
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error?.response?.data?.message || error?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      toast.error("Please enter your perfect domain.", {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  const handleChangeBussinessDomain = (e) => {
    setIsBusinessDomain(e.target.checked);
    setDomain("");
    if (e.target.checked) {
      setMaxlength(253);
    } else {
      setMaxlength(25);
    }
  };
  return (
    <Modal size="lg" isOpen={changeDomainModal} toggle={() => handleEmpty()}>
      <ModalHeader toggle={() => handleEmpty()}>{"Change Domain"}</ModalHeader>
      <ModalBody>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <select
            className="form-control my-2"
            onChange={(e) => setWhichPage(e.target.value)}
            value={whichPage}
            name="pageType"
          >
            <option value="singlePage">Single Page</option>
            <option value="multiPage">Multi Page</option>
            {/* {Object.keys(QR_GUNRATE_STATUS).map((elem) => {
              return <option value={QR_GUNRATE_STATUS[elem]}>{elem}</option>;
            })} */}
          </select>
          <input
            type="checkbox"
            id="set_domain"
            onChange={(e) => handleChangeBussinessDomain(e)}
            checked={isBusinessDomain}
          />{" "}
          <label htmlFor="set_domain">Set business domain</label>
          <Row>
            <Col md="12 mb-3">
              <Label htmlFor="validationCustom03">
                {isBusinessDomain
                  ? "Business Domain Name *"
                  : "Sub Domain Name *"}
              </Label>
              <InputGroup className="mt-3">
                <InputGroupText>{`https://`}</InputGroupText>
                <Input
                  className="form-control"
                  type="text"
                  value={domain}
                  onChange={(e) => {
                    setDomain(
                      isBusinessDomain
                        ? e.target.value
                            ?.toLocaleLowerCase()
                            ?.replace(/[^a-zA-Z0-9-.]/g, "")
                            ?.replace(/^https?:\/\//, "")
                        : e.target.value
                            ?.toLocaleLowerCase()
                            ?.replace(/[^a-zA-Z0-9]/g, "")
                            ?.replace(/^https?:\/\//, "")
                            ?.replace(/^www\./, "")
                    );
                    setIsAvailableDomain(false);
                  }}
                  min={0}
                  maxLength={maxlength}
                  onInput={maxLengthCheck}
                  onBlur={() => handleCheckSubDomain()}
                />
                {!isBusinessDomain && (
                  <InputGroupText>{`.${
                    domainDetails.mainDomain
                      ? domainDetails.mainDomain
                      : domainDetails.domain &&
                        getDomainWithoutSubdomain(domainDetails.domain)
                  }`}</InputGroupText>
                )}
              </InputGroup>
            </Col>
          </Row>
          <Button type="submit" disabled={!isAvailableDomain}>
            Submit
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ChangeDomainModal;
