export const randomStr = (length) => {
  let randomText = "";
  const randomString =
    "ABCDEFGHIJKLMNPPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
  for (let i = 0; i < length; i++) {
    randomText += randomString.charAt(
      Math.floor(Math.random() * randomString.length)
    );
  }
  return randomText;
};

/**
 * tabel serial nummber add func
 * @param {page number} page
 * @param {per page size} pageSize
 * @param {data arr} formvalue
 * @returns add sNo field and return new array
 */
export const tableId = (page, pageSize, formvalue) => {
  for (let i in formvalue) {
    formvalue[i]["sNo"] = page * pageSize - pageSize + 1 + +i;
  }
  return formvalue;
};

export const getDomainWithoutSubdomain = (url) => {
  // Remove protocol if present
  url = url.replace(/^(https?:\/\/)?/, "");

  // Remove subdomain and get the domain
  let domain = url.split(".").slice(1).join(".");
  if (!domain) {
    return url; // Return the original URL if there is no subdomain
  }
  return domain;
};

// export const isValidDomain = (domain) => {
//   const domainRegex =
//     /^(?!-)(?!https:\/\/)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,6})?$/;

//   // Check if the domain matches the regular expression
//   return domainRegex.test(domain);
// };
export const isValidDomain = (domain) => {
  const domainRegex =
    /^(?!-)([A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,6}|\.[A-Za-z]{2,6}\.[A-Za-z]{2,6})?)$/;

  return domainRegex.test(domain);
};

/**
 * Max length for fild number type helper
 */

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

export const isBusinessDomainFunc = (text) => {
  const pattern = /\./;
  return pattern.test(text);
};
